/*Global Varibles starts here */
$primary: #00A590;
$secondary: #f08ba9;
$dark: #313131;
$white: #ffffff;
$gray:#9c9c9c;
$bg-color: #E5ECED;
$whites-smoke: #f3f3f3;
$white-smoke: #f1f1f1;
$solidate: #d9d9d9;
$font-family-sans-serif: 'acuminpro-regular';
$font-size-base: 0.9rem !default;
$input-border-color: #CBCBCB !important;
$input-color: $dark;
$input-height: 45px;
$input-border-radius: 4px;
$input-placeholder-color: #A8A8A8 !default;
$input-plaintext-color: #fff !default;
$input-font-size: 14px !default;
$input-padding-y: 8px !default;
$input-padding-x: 9px !default;
$input-focus-box-shadow: $primary;
$input-border-radius: 6px !default;
$input-group-addon-bg: $primary;
$input-disabled-bg: $input-placeholder-color;
$input-disabled-color: $input-placeholder-color;
$input-line-height: 1.5;
$input-disabled-bg: #f0f0f0; // Background color for disabled input
$input-disabled-border: #f0f0f0; // Border color for disabled input
$input-disabled-text: $dark; // Text color for disabled input
$floating-label-text-color: $dark;
$floating-label-font-size: 12px;
$floating-label-top-margin: 4px;
$floating-label-transition-duration: 0.3s;
$floating-label-transition-timing-function: ease-in-out;
$input-field-border-bottom-color: #ccc;
$body-color: $dark;
$floating-label-padding: 5px;
/*input porperties ends here*/
/*label properties starts here */
$label-line-height: 1.5;
/*label properties starts here */
$h1-font-size: $font-size-base * 2.4 !default;
$h2-font-size: $font-size-base * 2.0 !default;
$h3-font-size: $font-size-base * 1.8 !default;
$h4-font-size: $font-size-base * 1.4 !default;
$h5-font-size: $font-size-base * 1.2 !default;
$h6-font-size: $font-size-base *1 !default;
$headings-color: $dark !default;
$headings-font-weight: 500 !default;
/*buttons properties starts here */
$btn-primary-color: $white;
$btn-primary-bg: $primary;
$btn-secondary-color: $white;
$btn-secondary-bg: $secondary;
$btn-border-width: 1px;
$btn-border-radius: 4px;
$btn-border-color: transparent;
$btn-padding-x: 12px;
$btn-padding-y: 6px;
$btn-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$btn-hover-bg: $secondary;
$btn-hover-color: $white;
$btn-active-bg: $primary;
$btn-disabled-opacity: 0.5;
/*buttons properties ends here */
/*table*/
$table-border-color: $solidate !important;
$table-bg: $white;
$table-accent-bg: transparent !important;
$table-cell-padding: 12px !important;
$table-font-size: 14px !important;
$table-head-bg: transparent !important;
$table-hover-bg: $white-smoke;

/*Global Varibles ends here */
/*Custom varibles  starts here */
@font-face {
    font-family: acuminpro-regular;
    src: url('assets/fonts/acumin-pro/Acumin-RPro.otf');
}

@font-face {
    font-family: acuminpro-bold;
    src: url('./assets/fonts/acumin-pro/Acumin-BdPro.otf');
}

/*buttons*/
.btn {

    &:hover {
        box-shadow: $btn-box-shadow;
    }

    cursor: pointer;
}

/*Tables*/
.table {
    >:not(:first-child) {
        border-top: none !important;
    }
}

/*form elements */
textarea {
    resize: none !important;
}

label {
    &.form-label {
        margin-bottom: 0px !important;
        font-weight: 400 !important;
        font-size: 14px !important;
    }
}

.form-floating {
    >.form-control {
        padding: 10px 4px 4px !important;
        height: 40px !important;
    }

    >label {
        padding: 4px !important;
    }
}

.form-floating {
    >label {
        line-height: 28px !important;
    }
}

/*Custom varibles  Ends here */

/*custom items related css starts here */

/* Toaster messages related css starts here */
.Toastify .Toastify__toast {
    line-height: 15px !important;
    font-size: 14px !important;
    font-family: $font-family-sans-serif !important;
}

.Toastify__toast-theme--light {
    background-color: $white !important;
    color: $dark !important;
}

/* Toaster messages related css ends here */

/*font sizes related css starts here */
.fs-xs {
    font-size: $font-size-base - 0.1rem !important;
}

.fs-sm {
    font-size: $font-size-base;
}

.fs-md {
    font-size: $font-size-base + 0.2rem !important;
}

/*font sizes related css ends here */

body {

    background-color: $bg-color !important;
    margin: 0px !important;
}

/*sidenavbar css starts here */


/*sidenavbar css ends here */
.header-lg {
    width: calc(100% - 20px) !important;
}

.side-menu.vh-calc-110 {
    height: calc(100vh - 110px) !important;
}

.side-menu {
    .menu-list {
        a {
            font-size: $font-size-base + 0.125rem;
            color: $white;

            &:hover,
            &:active,
            &.active {
                color: $secondary !important;
            }
        }


    }

}
/* scrollbar related style starts here */
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 5px !important;
    border-radius: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c2c1c1;
    border-radius: 6px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
}

.bg-whitesmoke {
    background-color: $white-smoke !important;
}

.custom-bg-body {
    background-color: $bg-color !important;
}

/* scrollbar related style ends here */

.input-pwd-pe-60 {
    input.form-control {
        padding-right: 30px !important;
        text-overflow: ellipsis;
    }
}

label {
    font-weight: 400 !important;
}

.fw-bold {
    font-family: acuminpro-bold;
}

.text-gray {
    color:$gray !important;
}

.or-text::after {
    position: absolute;
    content: "";
    background-color: $solidate;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.otp-inner-label label.flex-w-90 {
    display: none !important;
}

input.form-control.w-80px {
    width: 80px !important;
}

input.form-control.h-55px {
    height: 55px !important;
}

.side-menu {
    & .menu-list {
        & li {
            & a {
                &.active {
                    background-color: $primary !important;
                    color: $white ;

                    & svg.text-dark {
                        color: $white !important;
                    }

                    & div.text-dark {
                        color: $white !important;
                    }

                }

            }
        }
    }
}

.resourcelist-tab-panel {
    & input.form-control {
        min-width: 240px !important;
    }
}

.pe-34 {
    padding-right: 34px !important;
}
@mixin dropdown-control-styles {
    height: 38px !important;
    border-radius: 6px !important;
    cursor: pointer;
    width: 240px !important;
    padding-left: 28px !important;
}


.smart-dropdown {
    .Dropdown-control {
        @include dropdown-control-styles;
    }
}

.resourcelist-tab-panel {
    .smart-dropdown {
        .Dropdown-control {
            @include dropdown-control-styles;
        }
    }
}

.resourcelist-tab-panel {
    & .Dropdown-menu {
        min-width: 200px !important;
        margin-left: 0px !important;
        margin-top: 4px !important;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
}

.Dropdown-option {

    &.is-selected,
    &:hover {
        background-color: $bg-color !important;
    }
}

.smart-table {
    & table {
        & thead {
            & tr {
                & th {
                    background-color: #d8dada !important;
                    padding: 12px !important;
                }
            }
        }

        & tbody {
            & tr {
                & td {

                    padding: 12px !important;
                    line-height: 16px !important;
                }
            }
        }
    }
}

.Mui-selected {
    background-color: $primary !important;
    color: $white !important;
}

.mxh-calc-321 {
    max-height: calc(100vh - 321px) !important;
}

.p-multiselect {
    border: 1px solid $solidate !important;
}

.p-multiselect-label {
    padding: 8px !important;
    font-size: 14px !important;
}

.p-multiselect-label {
    & .p-placeholder {
        color: $input-placeholder-color !important;
    }
}

ul {
    &.p-multiselect-items {
        &.p-component {
            padding: 0px !important;
            background-color: $white !important;
        }
    }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
    background-color: $bg-color !important;
    color: $dark !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: #f3f4f6 !important;
    color: $dark !important;
}

.p-checkbox.p-highlight .p-checkbox-box {
    background-color: #6ac0ba !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    border-radius: 4px !important;
    font-size: 14px !important;
    padding: 4px 6px;
    margin-top: -10px;
}

.p-multiselect.p-focus {
    box-shadow: inherit !important;
    outline: inherit !important;
}

.bg-pinkswan {
    background-color: #DEDEDE !important;
}

.textarea-h-273 {
    & textarea.form-control {
        height: 273px !important;
    }
}
.textarea-h-232{
    & textarea.form-control {
        height: 232px !important;
    }
}

input.input-number:focus-visible {
    border: 0px !important;
    outline: 0px !important;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.error {
    &.text-danger {
        position: absolute;
        bottom: -11px !important;
        left: 0px !important;
        font-size: 12px !important;
    }
}

.smart-multiselect {
    .error {
        &.text-danger {

            bottom: -18px !important;

        }
    }
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    background-color: #f3f4f6 !important;
}

.p-multiselect {
    &.p-component {
        height: 40px !important;
    }
}

.p-multiselect-label {
    padding: 8px 5px !important;
    font-size: 14px !important;
    height: 39px !important;
}

.lh-30 {
    line-height: 30px !important;
}

.mx--2 {
    margin: 0px -8px !important;
}

.mih-calc-85 {
    min-height: calc(100vh - 85px);
}

.sharp-shadow {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

// Mixin for common dropdown arrow styles
@mixin dropdown-arrow($image-url) {
    border-color: transparent !important;
    border-style: none !important;
    border-width: inherit !important;
    background-image: url($image-url) !important;
    background-size: 10px;
    width: 10px !important;
    z-index: initial;
    height: 21px !important;
    background-repeat: no-repeat;
    border-width: 0px !important;
}

.Dropdown-arrow {
    @include dropdown-arrow('./assets/images/arrow-up.png');
}

.is-open {
    & .Dropdown-arrow {
        @include dropdown-arrow('./assets/images/arrow-down.png');
    }
}

.btn {
    &.btn-primary-outline {
        border: 1px solid $primary !important;
        background-color: transparent !important;
        color: $primary !important;
    }
}

.smart-dropdown {
    & .Dropdown-placeholder {
        padding-top: 2px !important;

        &.is-selected {
            padding-top: 2px !important;
        }
    }

}

.smart-date-picker {
    left: auto !important;
    z-index: 999999 !important;
    margin-left: -317px !important;
    margin-top: 5px !important;
}

.smart-date-picker {
    & .rdrStaticRangeLabel {
        padding: 12px 20px !important;
        font-size: 14px !important;
        line-height: 27px !important;
    }
}

.smart-date-picker {
    & .rdrStaticRange {
        &.rdrStaticRangeSelected {
            color: $primary !important;
        }
    }
}

.smart-date-picker {
    .rdrStaticRange {

        &:hover .rdrStaticRangeLabel,
        &:focus .rdrStaticRangeLabel {
            background-color: #d0e8e3 !important;
        }
    }
}

.smart-date-picker {
    & .rdrDateDisplayWrapper {

        background-color: #e5eced !important;
        border-bottom: 1px solid #e2dfdf;
    }
}

.smart-date-picker {
    & .rdrDateDisplayItemActive {
        border-color: $secondary !important;
        background-color: $secondary !important;


        & input {
            color: $white !important;
            font-size: 14px !important;
        }
    }
}

.smart-date-picker {
    & .rdrDateDisplayItem {
        & input {

            font-size: 14px !important;
        }
    }
}

.smart-date-picker {
    & .rdrNextPrevButton {
        background-color: $bg-color !important;
    }

    & .rdrNextPrevButton {
        background-color: $bg-color !important;
    }
}

.smart-date-picker {

    & .rdrMonthPicker,
    & .rdrYearPicker {
        select {
            width: 100px !important;
            background-color: #f4f4f4 !important;
            font-size: 14px !important;
        }
    }
}

.smart-date-picker {
    & .rdrMonthAndYearPickers {
        & select {
            & :hover {
                background-color: $bg-color !important;
            }
        }
    }
}

.smart-date-picker {
    & .rdrDay {
        .rdrDayHovered {
            border-color: $secondary !important;
        }
    }
}

.rdrDayToday {

    & .rdrDayNumber {
        & span:after {
            background-color: $primary !important;
        }
    }

}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    background-color: $primary !important;
}

.smart-date-picker {
    & .rdrWeekDay {
        color: $dark !important;
        font-weight: 700 !important;
        font-size: 13px !important;
    }

}

.smart-date-picker {
    & .rdrStaticRange {
        background-color: transparent !important;
    }
}

.smart-date-picker {
    & .rdrDefinedRangesWrapper {
        background-color: #faf9f9 !important;
    }
}

.smart-date-picker {
    background-color: #f4f4f4 !important;
}

.smart-date-picker {
    & .rdrStaticRange {
        border-bottom: 1px solid transparent !important;
    }
}

.smart-date-picker {

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        color: $secondary !important;
    }
}

.deep-shadow {
    box-shadow: rgba(0, 0, 0, 0.3) 13px 12px 40px, rgba(0, 0, 0, 0.22) 0px 8px 12px !important;

}

.border-dotted-solidate {
    border: 1px dotted $solidate;
    border-width: 2px !important;
}

.mxh-312 {
    max-height: 312px !important;
}

.smart-dropdown {
    .p-dropdown.p-inputwrapper {
        & .p-dropdown-label.p-inputtext {
            padding: 7px 12px !important;
            font-size: 14px !important;
            padding-left: 28px !important;
        }
    }
}

.smart-dropdown {
    & .p-dropdown.p-inputwrapper {
        width: 240px !important;
        border: 1px solid $solidate !important;
    }

    & .p-dropdown.p-inputwrapper.p-focus {
        border: 1px solid $solidate !important;
        border-radius: 4px !important;
        outline: 0px !important;
        box-shadow: inherit !important;
    }
}

.smart-dropdown {
    & .p-dropdown-trigger {
        & svg {

            color: #aeaeb1 !important;
            font-size: 12px !important;
            width: 13px !important;
        }
    }
}

.smart-dropdown {
    & .p-dropdown-trigger {
        width: 35px !important;
    }
}

.p-dropdown-panel {
    & .p-dropdown-items {
        padding: 0px !important;
        margin-bottom: 0px !important;
    }

    & .p-dropdown-item {
        font-size: 14px !important;
    }

}

.p-dropdown-panel {
    .p-dropdown-items {
        .p-dropdown-item.p-highlight {
            background-color: #f3f4f6 !important;
            color: $dark !important;
        }

    }
}

.p-dropdown-panel {
    .p-dropdown-items {
        .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
            background-color: $bg-color !important;
        }
    }
}

.flex-18 {
    flex: 0 0 18px !important;
}

.mxh-160 {
    max-height: 160px !important;
}

.smart-multiselect {
    & .p-multiselect-label {
        &.p-placeholder {
            color: $input-placeholder-color !important;
            padding-left: 8px !important;
        }
    }

    & .p-multiselect-trigger {
        width: 24px !important;
        color: $input-placeholder-color !important;
        padding-right: 11px !important;
    }

}

.p-multiselect-panel {
    & .p-checkbox-box {
        border: 1px solid $primary !important;
    }
}

.p-multiselect-header {
    background-color: $white-smoke !important;
}

.p-multiselect-item {
    font-size: 14px !important
}

.p-multiselect-token {
    & svg {
        width: 13px !important;
        color: $secondary;
    }
}

.p-tooltip {
    &.p-tooltip-bottom {
        &.p-tooltip-active {
            width: 200px !important;
            border-radius: $input-border-radius;
            max-height: 250px !important;
            overflow-y: auto;
            margin-left: 250px !important;
            left: 0px !important;
        }
    }
}

.p-tooltip {
    &.p-tooltip-bottom {
        &.p-tooltip-active {
            & .p-tooltip-text {
                background-color: $dark !important;
                color: $white !important;
                box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
            }
        }
    }
}

.smart-table {
    & table {
        & tbody {

            & tr.moreoptions-position-fixed:nth-last-child(1) {
                & td.actions-column {
                    & .custom-dropdown-menu {
                        margin-top: -121px !important;
                        position: fixed !important;
                        top: auto !important;
                        margin-left: -112px !important;
                    }
                }
            }
        }
    }
}

.smart-table {
    & table {
        & tbody {
            & tr:nth-last-child(1) {
                & td.actions-column {
                    & .custom-dropdown-menu {
                        margin-top: -73px !important;
                        margin-left: -116px !important;
                    }
                }
            }

            & tr:nth-last-child(2) {
                & td.actions-column {
                    & .custom-dropdown-menu {
                        margin-top: -53px !important;
                        margin-left: -117px !important;
                    }
                }
            }

            & tr:nth-last-child(3) {
                & td.actions-column {
                    & .custom-dropdown-menu {
                        margin-top: -58px !important;
                        margin-left: -117px !important;
                    }
                }
            }
        }
    }
}

.smart-table {
    & table {
        & tbody {
            & tr:nth-last-child(1) {
                & td.actions-column.dropdown-with-two-options {
                    & .custom-dropdown-menu {
                        margin-top: -77px !important;
                    }
                }
            }

        }
    }
}

.smart-table {
    & table {
        & tbody {
            & tr:nth-last-child(1) {
                & td.actions-column.dropdown-with-two-options {
                    & .custom-dropdown-menu {
                        margin-top: -77px !important;
                    }
                }
            }

        }
    }
}

.smart-table {
    & table {
        & tbody {
            & tr:nth-last-child(3) {
                & td.actions-column.dropdown-with-two-options {
                    & .custom-dropdown-menu {
                        margin-top: 18px !important;
                    }
                }
            }

        }
    }
}

.smart-table {
    & table {
        & tbody {
            & tr.moreoptions-position-fixed {
                & td.actions-column.dropdown-with-two-options {
                    & .custom-dropdown-menu {
                        margin-top: -92px !important;
                    }
                }
            }

        }
    }
}


.form-group {
    & label {
        &.text-danger {
            position: absolute;
            bottom: -18px !important;
            font-size: 12px !important;
        }
    }
}

.text-danger {
    &.bottom-minus-18 {
        bottom: -18px !important;
    }
}

.bg-solitude {
    background-color: #f3f4f6 !important;
}

input {
    &.form-control.p-2 {

        padding: 4px !important;
    }

    &.form-control.pt-6 {
        padding-top: 6px !important;
    }
}

.bg-white {
    background-color: $white;
}

.bg-white {
    &.z-intial {
        z-index: initial !important;
    }
}

.smart .smart-table {
    & table {
        & tbody {
            & tr:nth-last-child(2) {
                & td {
                    &.actions-column.dropdown-with-two-options {
                        & .custom-dropdown-menu {
                            margin-top: -59px !important;
                            margin-left: -119px !important;
                        }
                    }
                }
            }
        }
    }
}
.form-check-input{
    &.w-h-20{
        width: 20px !important;
        height: 20px !important;
        flex: 0 0 20px !important;
    }
}
.mt-32{
    margin-top: 32px !important;
}
.smart-dropdown.ps-12-p-dropdown-label {
    .p-dropdown.p-inputwrapper {
        & .p-dropdown-label.p-inputtext {
            padding-left: 12px !important;
        }
    }
}

.smart-dropdown {
    &.p-dropdown-w-240{
        & .p-dropdown.p-inputwrapper{
           width: 240px !important;
        }
    }
}
.image-selected::after{
    position: absolute;
    content: "";
    background-color: $dark;
    height: 100%;
    width: 100%;
    left: 0;
    top:0;
    opacity: 0.3;
    z-index: 1;
    border-radius: 4px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.smart-table.table-datat-mw-140 table tr td,
.smart-table.table-datat-mw-140 table tr th{
    min-width: 140px;
}
.smart-table table tbody tr:nth-last-child(3) td.actions-column .custom-dropdown-menu.ml--142,
.smart-table table tbody tr:nth-last-child(1) td.actions-column .custom-dropdown-menu.ml--142,
.smart-table table tbody tr:nth-last-child(2) td.actions-column .custom-dropdown-menu.ml--142{
    margin-left: -142px !important;
}
.textarea-h-273 .quill {
    height: 250px !important;
}
.ql-picker-label{
    background-color: #e7e7e7;
    padding: 0px;
    border-radius: 4px;
}
.btn.status-badge{
    padding: 3px !important;
    line-height: 14px;
    border-radius: 8px;
}
.mxh-calc-379 {
    max-height: calc(100vh - 379px) !important;
}
/*custom items related css ends here */