@tailwind base;
@tailwind components;
@tailwind utilities;

@import"./global.scss";
@import "../design-custom-modules/bootstrap/scss/bootstrap.scss";

@layer utilities {

  .hover\\:text-primary:hover {
    color: $primary;
  }

  .hover\:bg-light-primary:hover {
    background-color: theme('colors.light-primary');
  }
}